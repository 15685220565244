import AuthMiddleware from "@/middleware/auth"

function lazyLoad(view) {
  return () => import(`@/views/cases/${view}.vue`)
}
const CaseRouter = [
  {
    path: "/new-case",
    name: "NewCase",
    component: lazyLoad("NewPatientCase"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "NEW CASE",
    },
  },

  {
    path: "/concurrent-case-info/:case_id/edit",
    name: "Concurrent-Case-Information-Update",
    component: lazyLoad("ConcurrentCase"),
    beforeEnter: AuthMiddleware.user,
  },

  {
    path: "/all-cases",
    name: "AllCases",
    component: lazyLoad("AllCases"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "ALL CASES",
    },
  },

  {
    path: "/all-cases/active",
    name: "ActiveCases",
    beforeEnter: AuthMiddleware.user,
    component: lazyLoad("ActiveCases"),
    meta: {
      title: "ALL CASES",
    },
  },
  {
    path: "/all-cases/pending",
    name: "PendingCases",
    beforeEnter: AuthMiddleware.user,
    component: lazyLoad("PendingCases"),
    meta: {
      title: "ALL CASES",
    },
  },
  {
    path: "/all-cases/finalized",
    name: "CompleteCases",
    beforeEnter: AuthMiddleware.user,
    component: lazyLoad("CompleteCases"),
    meta: {
      title: "ALL CASES",
    },
  },
  {
    path: "/all-cases/archive",
    name: "ArchiveCase",
    beforeEnter: AuthMiddleware.user,
    component: lazyLoad("ArchiveCase"),
    meta: {
      title: "ALL CASES",
    },
  },
  {
    path: "/all-cases/new-reports",
    name: "NewReportCases",
    beforeEnter: AuthMiddleware.user,
    component: lazyLoad("NewReportCases"),
    meta: {
      title: "NEW REPORTS",
    },
  },
  {
    path: "/all-cases/rejected",
    name: "RejectedCases",
    beforeEnter: AuthMiddleware.user,
    component: lazyLoad("RejectionCases"),
    meta: {
      title: "ALL CASES",
    },
  },
  {
    path: "/all-cases/pending-list",
    name: "PendingCasesList",
    beforeEnter: AuthMiddleware.user,
    component: lazyLoad("PendingCasesList"),
    meta: {
      title: "ALL CASES",
    },
  },
  {
    path: "/case-info/:case_id/edit",
    name: "Case-Information-Update",
    component: lazyLoad("NewPatientCase"),
    beforeEnter: AuthMiddleware.user,
  },

  {
    path: "/cases/:case_id",
    name: "CaseView",
    component: lazyLoad("ViewCase"),
    beforeEnter: AuthMiddleware.user,
  },

  {
    path: "/lab-daq/pending-orders",
    name: "LabdaqPendingOrders",
    component: lazyLoad("LabdaqPendingOrders"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "LABDAQ ORDERS",
    },
  },
  {
    path: "/lab-daq/pending-results",
    name: "LabdaqPendingResults",
    component: lazyLoad("LabdaqPendingResults"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "LABDAQ RESULTS",
    },
  },
  {
    path: "/validation-cases",
    name: "ValidationCases",
    component: lazyLoad("IsValidationCases"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "VALIDATION CASES",
    },
  },
  {
    path: "/validation-cases/active",
    name: "ActiveValidationCases",
    component: lazyLoad("ActiveValidations"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "VALIDATION CASES",
    },
  },
  {
    path: "/validation-cases/pending",
    name: "PendingValidationCases",
    component: lazyLoad("PendingValidations"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "VALIDATION CASES",
    },
  },
  {
    path: "/validation-cases/complete",
    name: "CompleteValidationCases",
    component: lazyLoad("CompleteValidations"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "VALIDATION CASES",
    },
  },
  {
    path: "/validation-cases-arch",
    name: "ArchiveValidationCases",
    component: lazyLoad("ArchiveValidations"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "VALIDATION CASES",
    },
  },
  {
    path: "/all-billing-cases/pending",
    name: "AllPendingBilledCases",
    component: lazyLoad("AllBilledCases"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "Unbilled Cases",
    },
  },
  {
    path: "/all-billing-cases/billed",
    name: "BilledCases",
    component: lazyLoad("StatusBasedBilledCases"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "Billed Cases",
    },
  },
  {
    path: "/all-billing-cases/client-billed",
    name: "ClientBilledCases",
    component: lazyLoad("ClientCases"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "Client Cases",
    },
  },
  {
    path: "/all-billing-cases/exception-billed",
    name: "ExceptionBilledCases",
    component: lazyLoad("ExceptionBilledCases"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "Exception Cases",
    },
  },
  {
    path: "/all-billing-cases/on-hold",
    name: "OnholdBilledCases",
    component: lazyLoad("OnholdBilledCases"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "Onhold Cases",
    },
  },
  {
    path: "/all-billing-cases/billed-exception",
    name: "BilledExceptionBilledCases",
    component: lazyLoad("BilledWithException"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "Billed With Exception Cases",
    },
  },
  {
    path: "/all-billing-cases/billed-patient-claim",
    name: "BilledPatientBilledCases",
    component: lazyLoad("BilledWithPatient"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "Billed With Patient Claim Cases",
    },
  },
  {
    path: "/all-billing-cases/mileage-cases",
    name: "MileageCases",
    component: lazyLoad("MileageCases"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "MILEAGE CASES",
    },
  },
  {
    path: "/lab-daq/invalid-orders",
    name: "LabdaqInvalidOrders",
    component: lazyLoad("LabdaqInvalidOrders"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "LABDAQ RESULTS",
    },
  },
  {
    path: "/lab-daq/completed-orders",
    name: "LabdaqCompletedOrders",
    component: lazyLoad("LabdaqCompletedOrders"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "LABDAQ RESULTS",
    },
  },
  {
    path: "/all-billing-cases/coding",
    name: "CodingCases",
    component: lazyLoad("CodingCases"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "CODING CASES",
    },
  },
  {
    path: "/all-cases/emr",
    name: "EMRNotSend",
    beforeEnter: AuthMiddleware.user,
    component: lazyLoad("EMRNotSend"),
    meta: {
      title: "ALL CASES",
    },
  },
  {
    path: '/add-milage-case/:case_id',
    name: 'AddMilageCase',
    beforeEnter: AuthMiddleware.user,
    component: lazyLoad("AddMilageCase"),
    meta: {
      title: "ADD MILEAGE",
    },
  },
  {
    path: '/mileage-case/:case_id/:m_id',
    name: 'MilageCaseView',
    beforeEnter: AuthMiddleware.user,
    component: lazyLoad("ViewMileageCase"),
    meta: {
      title: "MILEAGE CASE",
    },
  },
  {
    path: "/mileage-case-info/:case_id/edit/:m_id",
    name: "Mileage-Case-Information-Update",
    component: lazyLoad("NewMileageCase"),
    beforeEnter: AuthMiddleware.user,
  },
  {
    path: "/all-billing-cases/exception-resolved",
    name: "ExceptionResolvedCases",
    component: lazyLoad("ExceptionResolvedCases"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "EXCEPTION RESOLVED",
    },
  },
  {
    path: "/all-cases/release-pending",
    name: "ReleasePending",
    component: lazyLoad("ReleasePendingCases"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "REQUIRES ATTENTION",
    },
  },
  {
    path: "/all-billing-cases/exception-reviewed",
    name: "ExceptionReviewedCases",
    component: lazyLoad("ExceptionReviewedCases"),
    beforeEnter: AuthMiddleware.user,
    meta: {
      title: "EXCEPTION REVIEWED",
    },
  },
]

export { CaseRouter }
